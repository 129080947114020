export const AppConfig = {
	production: false,
	environment: 'STAGING',

	// orderManagerApiUrl: 'http://localhost:4200/api', // proxy.config -- proxy to https://ordermanagerdev.azurewebsites.net
	orderManagerApiUrl: 'https://ordermanager-stg.azurewebsites.net',
	tagmeAdminApiUrl: 'https://tagmeapistaging.azurewebsites.net/',
	customersApiUrl: 'https://customersapi-stg.azurewebsites.net',
	wsUrl: 'https://customersapi-stg.azurewebsites.net/order',

	deliveryUrl: 'https://reservation-widget-staging.azurewebsites.net/delivery/',
	dineinUrl: 'https://live-menu-staging.tagme.com.br/menu/',
	liveMenuURL: 'https://live-menu-staging.tagme.com.br/',
	waiterURL: 'https://waiter-stg.tagme.com.br/',
}
